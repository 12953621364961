.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root{
  background: #FFFAEA !important;
  color: #141414 !important;
  position: fixed;
}

.css-1q39md6-MuiButtonBase-root-MuiButton-root{
  color: #141414 !important;
} 


@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Fun:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');

h1, h2, h3, h4 {
  font-family: "Reem Kufi Fun", sans-serif;
} 

p{
  font-family: "Readex Pro", sans-serif;
}

.hover-underlines {
  position: relative;
  display: inline-block;
}

.hover-underlines::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px; /* Thickness of the underline */
  background-color: #141414; /* Color of the underline */
  bottom: -3px; /* Gap between text and underline */
  left: 0;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.hover-underlines:hover::after {
  transform: scaleX(1);
  transform-origin: bottom ;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}